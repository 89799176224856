<template>
	<div>
		<div class="documentbx" :style="{height:documentlist.length>0?'':'100vh'}">
			<div class="download" v-for="(item,index) in documentlist" :key="index">
				<h1>{{item.title}}</h1>
				<div>{{item.fileName}}</div>
				<p>{{item.createTime}}</p>
				<el-button size="mini" type="primary" @click="subdocument(item.filePath)">下载</el-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DocDown",
	data(){
		return {
			documentlist:[],
		}
	},
	mounted() {
		this.download();
	},
	methods:{
		//  获取文档列表
		async download() {
			const{data:res}=await this.$http.get('/gw/news/getWzDocList?limit=100');
			this.documentlist=res.data;
		},
		//  点击按钮下载
		subdocument(res) {
			window.open(res);
		}
	}
}
</script>

<style scoped>
.menu>.el-button {
	border-radius: 0;
	margin: 0;
	border: 0;
	border-left: 1px solid #bdbdbd;
	background-color: #0f91ee;
	color: #fff;
}
.introduction1>div {
	display: inline-block;
	width: 300px;
	/* margin-top: 50PX;
	margin-left: 150px; */
}
.introduction1>div>img{
	padding-top: 50px;
	padding-left: 95px;
}
.introduction1>div>h3{
	color: #717171;
	text-align: center;
	margin-top: 8px ;
	
}
.introduction1>div>p{
	color: #717171;
	text-align: center;
	width: 250px;
	margin-left: 20px;
}
.xm>h2{
	margin-left: 19%;
}
.el-dropdown + .el-dropdown {
	margin-left: 15px;
	width: 500px !important;
}
.cooperation>div {
	display: inline-block;
	margin-right: 1%;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 15%;
	height: 50px;
	border: 1px solid #717171;
	text-align: center;
	overflow: hidden;
	font-size: 13px;
}
.cooperation>div>span {
	width: 188px;
	height: 50px;
	text-align: center;
	margin-top: 50px;
	line-height: 44px;
}
.download {
	width: 66%;
	height: 150px;
	/* background-color: #0f91ee; */
	margin: 0 auto;
	border-bottom: 1px solid #717171;
	
	
}
.download>h1 {
	width: 100%;
	height: 15px;
	
}
.documentbx {
	width: 100%;
	height: 100%;
	/* background-color: aqua; */
	
}
.download>div {
	width: 100%;
	height: 15px;
	font-size: 17px;
	margin-top: 23px;
	margin-bottom: 23px;
}

</style>
